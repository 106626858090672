import { getConfiguratorString } from "./homePage.js";
import { prevP, nextP } from './tyresPage.js';
import sortProducts from './sort.js';
import { addToCartTyrePage, goToCart, increaseInCart, deleteItem, whatShows, collectPurchaseData } from "./cart.js";
import { buyWithCard } from './checkCardPayment.js';
import { map } from './leaflet.js';
import { login, logout } from './login.js';
import { addTyre, editTyre, deleteTyre, addTyres, deleteTyres, deleteTyresKeepPhoto, addAction, deleteAction, setCustomDiscount, setPictureCustom, deleteTyresCustom, isTyreVisible, setPricesFromCMS } from "./admin.js";
import menu from './menu.js';
// import acceptCookies from './cookiePolicy.js'; // 💾 Old way, not in use now.
import sendForm from "./solidWheel.js";

const pagetype = document.querySelector('main').dataset.pagetype;

menu();
goToCart();
// acceptCookies(); // 💾 Old way, not in use now. 

if (pagetype === 'home'){
    getConfiguratorString();
}

if (pagetype === 'tyres' || pagetype === 'similarTyres'){
    prevP();
    nextP();
    sortProducts();
}

if (pagetype === 'tyre'){
    addToCartTyrePage();
}

if (pagetype === 'cart'){
    increaseInCart();
    deleteItem();
    whatShows();
    collectPurchaseData();
}

if(pagetype === 'thankYouCardPayment'){
    buyWithCard();
}

if(pagetype === 'contact-page'){
    map();
}

if(pagetype === 'login'){
    login();
}

if(pagetype === 'admin'){
    logout();
    addTyre();
    editTyre();
    deleteTyre();
    addTyres();
    deleteTyres();
    deleteTyresCustom();
    deleteTyresKeepPhoto();
    addAction();
    deleteAction();
    setCustomDiscount();
    setPictureCustom();
    isTyreVisible();
    setPricesFromCMS();
}

if(pagetype === 'solidWheel'){
    sendForm();
}