import createOptionsUrl from './createOptionsUrl.js';


export default function sortProducts (){
    const sortBtn = document.getElementById('sort');

    sortBtn.addEventListener('change', function(e){
        let url = window.location.href;
        let sort = e.target.options[e.target.selectedIndex].value;
        let original = `sortiranje=${sort}`;

        url = createOptionsUrl(url, original, 'sortiranje=');
        window.location.href = url;
    });
}