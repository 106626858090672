import { showAlert } from './alert.js';


export async function addTyre(){

    const sendForm = async (e) => {
        e.preventDefault();

        const section = document.querySelector('.section-add-product');
        // console.log('🏀 section...: ', section.querySelector('#photos').files);

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('EAN', section.querySelector('#EAN').value);
        form.append('naziv', section.querySelector('#naziv').value);
        form.append('proizvodjac', section.querySelector('#proizvodjac').value);
        form.append('model', section.querySelector('#model').value);
        form.append('sezona', section.querySelector('#sezona').value);
        form.append('opis', section.querySelector('#opis').value);
        form.append('cena', section.querySelector('#cena').value);
        form.append('visina', section.querySelector('#visina').value);
        form.append('sirina', section.querySelector('#sirina').value);
        form.append('precnik', section.querySelector('#precnik').value);
        form.append('indeksBrzine', section.querySelector('#indeksBrzine').value);
        form.append('indeksNosivosti', section.querySelector('#indeksNosivosti').value);
        
        if(section.querySelector('#cenaNaPopustu').value){
            form.append('cenaNaPopustu', section.querySelector('#cenaNaPopustu').value);
        }
        if(section.querySelector('#efikasnostPotrosnje').value){
            form.append('efikasnostPotrosnje', section.querySelector('#efikasnostPotrosnje').value);
        }
        if(section.querySelector('#prianjanjeNaMokrom').value){
            form.append('prianjanjeNaMokrom', section.querySelector('#prianjanjeNaMokrom').value);
        }
        if(section.querySelector('#buka').value){
            form.append('buka', section.querySelector('#buka').value);
        }
        if(section.querySelector('#rof').value){
            form.append('rof', section.querySelector('#rof').value);
        }
        if(section.querySelector('#vidljivProizvod').value){
            form.append('vidljivProizvod', section.querySelector('#vidljivProizvod').value);
        }
        if(section.querySelector('#slika').files[0]){
            // console.log('🏀', section.querySelector('#slika').files[0]);
            form.append('slika', section.querySelector('#slika').files[0]);
        }
        


        // 2 - Provera unetih podataka
        if(!section.querySelector('#EAN').value) return showAlert('error', 'Obavezno je navesti EAN');
        if(!section.querySelector('#naziv').value) return showAlert('error', 'Obavezno je navesti naziv');
        if(!section.querySelector('#proizvodjac').value) return showAlert('error', 'Obavezno je navesti proizvodjača');
        if(!section.querySelector('#model').value) return showAlert('error', 'Obavezno je navesti model');
        if(!section.querySelector('#sezona').value) return showAlert('error', 'Obavezno je navesti sezonu');
        if(!section.querySelector('#opis').value) return showAlert('error', 'Obavezno je navesti opis');
        if(!section.querySelector('#cena').value) return showAlert('error', 'Obavezno je navesti cenu');
        if(!section.querySelector('#visina').value) return showAlert('error', 'Obavezno je navesti visinu');
        if(!section.querySelector('#sirina').value) return showAlert('error', 'Obavezno je navesti širinu');
        if(!section.querySelector('#precnik').value) return showAlert('error', 'Obavezno je navesti prečnik');
        if(!section.querySelector('#indeksBrzine').value) return showAlert('error', 'Obavezno je navesti indeks brzine');
        if(!section.querySelector('#indeksNosivosti').value) return showAlert('error', 'Obavezno je navesti indeks nosivosti');


        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'post',
                url: '/admin/tyre',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste dodali proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            // console.log(err.response); //
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-add-product .admin-form__btn').addEventListener('click', sendForm);
}


export async function editTyre(){

    const sendForm = async (e) => {
        e.preventDefault();

        const section = document.querySelector('.section-edit-product');
        // console.log('🏀 section...: ', section.querySelector('#photos').files);

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('nameEntry', section.querySelector('#nameEntry').value);

        if(section.querySelector('#EAN').value){
            form.append('EAN', section.querySelector('#EAN').value);
        }
        if(section.querySelector('#proizvodjac').value){
            form.append('proizvodjac', section.querySelector('#proizvodjac').value);
        }
        if(section.querySelector('#model').value){
            form.append('model', section.querySelector('#model').value);
        }
        if(section.querySelector('#sezona').value){
            form.append('sezona', section.querySelector('#sezona').value);
        }
        if(section.querySelector('#opis').value){
            form.append('opis', section.querySelector('#opis').value);
        }
        if(section.querySelector('#cena').value){
            form.append('cena', section.querySelector('#cena').value);
        }
        if(section.querySelector('#cenaNaPopustu').value){
            form.append('cenaNaPopustu', section.querySelector('#cenaNaPopustu').value);
        }
        if(section.querySelector('#visina').value){
            form.append('visina', section.querySelector('#visina').value);
        }
        if(section.querySelector('#sirina').value){
            form.append('sirina', section.querySelector('#sirina').value);
        }
        if(section.querySelector('#precnik').value){
            form.append('precnik', section.querySelector('#precnik').value);
        }
        if(section.querySelector('#efikasnostPotrosnje').value){
            form.append('efikasnostPotrosnje', section.querySelector('#efikasnostPotrosnje').value);
        }
        if(section.querySelector('#prianjanjeNaMokrom').value){
            form.append('prianjanjeNaMokrom', section.querySelector('#prianjanjeNaMokrom').value);
        }
        if(section.querySelector('#buka').value){
            form.append('buka', section.querySelector('#buka').value);
        }
        if(section.querySelector('#indeksBrzine').value){
            form.append('indeksBrzine', section.querySelector('#indeksBrzine').value);
        }
        if(section.querySelector('#indeksNosivosti').value){
            form.append('indeksNosivosti', section.querySelector('#indeksNosivosti').value);
        }
        if(section.querySelector('#rof').value){
            form.append('rof', section.querySelector('#rof').value);
        }
        if(section.querySelector('#vidljivProizvod').value){
            form.append('vidljivProizvod', section.querySelector('#vidljivProizvod').value);
        }
        if(section.querySelector('#slika').files[0]){
            // console.log('🏀', section.querySelector('#slika').files[0]);
            form.append('slika', section.querySelector('#slika').files[0]);
        }

        // 2 - Provera unetih podataka
        if(!section.querySelector('#nameEntry').value) return showAlert('error', 'Obavezno je navesti naziv');

        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'patch',
                url: '/admin/tyre',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste izmenili proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-edit-product .admin-form__btn').addEventListener('click', sendForm);
}


export async function deleteTyre(){

    const sendForm = async (e) => {
        e.preventDefault();
        // const section = document.querySelector('.section-delete-main-product');

        // 1 - Kreiranje simulirane multipart forme
        let nameEntry = document.querySelector('.section-delete-product #nameEntry').value;

        // 2 - Provera unetih podataka
        if(!nameEntry) return showAlert('error', 'Obavezno je navesti naziv');

        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'delete',
                url: '/admin/tyre',
                data: {
                    nameEntry
                }
            });
            // console.log('🎾 res: ', res);

            if(res.status == 204){
                showAlert('success', 'Uspešno ste izbrisali proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-delete-product .admin-form__btn').addEventListener('click', sendForm);
}


export async function addTyres(){
    const sendForm = async (e) => {
        e.preventDefault();

        const section = document.querySelector('.section-add-products');
        // console.log('🏀 section...: ', section.querySelector('#csv-file').files);

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('csv-file', section.querySelector('#csv-file').files[0]);

        // 2 - Provera unetih podataka
        if(!section.querySelector('#csv-file').value) return showAlert('error', 'Obavezno je uneti csv fajl.');
        // return console.log('🏀 addTyres');

        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'post',
                url: '/admin/tyres',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste dodali proizvode.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-add-products .admin-form__btn').addEventListener('click', sendForm);
}


export async function deleteTyresCustom(){

    const sendForm = async (e) => {
        e.preventDefault();
        let proizvodjac = document.querySelector('.section-custom-delete-products #nameEntry').value.toUpperCase();
        let sezona = document.querySelector('.section-custom-delete-products #sezona').value.toUpperCase();

        if(!proizvodjac) return showAlert('error', 'Obavezno je navesti proizvođača');

        const filterObj = { proizvodjac };
        if(sezona) filterObj.sezona = sezona;

        try {
            const res = await axios({
                method: 'delete',
                url: '/admin/tyresCustom',
                data: filterObj
            });
            // console.log('🏀 res: ', res);

            if(res.status == 204){
                showAlert('success', 'Uspešno ste obrisali gume.');
                window.setTimeout(() => location.reload(), 3 * 1000);
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.'); // ⏳
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-custom-delete-products .admin-form__btn').addEventListener('click', sendForm);
}


export async function deleteTyres(){

    const sendForm = async (e) => {
        e.preventDefault();

        const section = document.querySelector('.section-delete-products');
        // return console.log('🏀 section...: ', section.querySelector('#csv-file').files);

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('csv-file', section.querySelector('#csv-file').files[0]);

        // 2 - Provera unetih podataka
        if(!section.querySelector('#csv-file').value) return showAlert('error', 'Obavezno je uneti csv fajl.');

        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'delete',
                url: '/admin/tyres',
                data: form
            });

            // console.log('🏀', res);

            if(res.status === 204){
                showAlert('success', 'Uspešno ste obrisali proizvode.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-delete-products .admin-form__btn').addEventListener('click', sendForm);
}


export async function deleteTyresKeepPhoto(){

    const sendForm = async (e) => {
        e.preventDefault();

        const section = document.querySelector('.section-delete-products-keepPhoto');
        // return console.log('🏀 section...: ', section.querySelector('#csv-file').files);

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('csv-file', section.querySelector('#csv-file').files[0]);
        form.append('keepPhoto', true); // 🎁
        
        // 2 - Provera unetih podataka
        if(!section.querySelector('#csv-file').value) return showAlert('error', 'Obavezno je uneti csv fajl.');

        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'delete',
                url: '/admin/tyres',
                data: form
            });

            // console.log('🏀', res);

            if(res.status === 204){
                showAlert('success', 'Uspešno ste obrisali proizvode.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-delete-products-keepPhoto .admin-form__btn').addEventListener('click', sendForm);
}


export async function addAction(){

    const sendForm = async (e) => {
        e.preventDefault();

        const section = document.querySelector('.section-add-action');
        // console.log('🏀 section...: ', section.querySelector('#photos').files);


        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('naziv', section.querySelector('#naziv').value);
        form.append('opis', section.querySelector('#opis').value);
        
        if(section.querySelector('#slika').files[0]){
            // console.log('🏀', section.querySelector('#slika').files[0]);
            form.append('slika', section.querySelector('#slika').files[0]);
        }
        

        // 2 - Provera unetih podataka
        if(!section.querySelector('#naziv').value) return showAlert('error', 'Obavezno je navesti naziv');
        if(!section.querySelector('#opis').value) return showAlert('error', 'Obavezno je navesti opis');


        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'post',
                url: '/admin/action',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste dodali akciju.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            console.log('🏀 err.response:', err.response);
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-add-action .admin-form__btn').addEventListener('click', sendForm);
}


export async function deleteAction(){

    const sendForm = async (e) => {
        e.preventDefault();

        // 1 - Kreiranje simulirane multipart forme
        let nameEntry = document.querySelector('.section-delete-action #nameEntry').value;

        // 2 - Provera unetih podataka
        if(!nameEntry) return showAlert('error', 'Obavezno je navesti naziv');

        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'delete',
                url: '/admin/action',
                data: {
                    nameEntry
                }
            });
            // console.log('🎾 res: ', res);

            if(res.status == 204){
                showAlert('success', 'Uspešno ste izbrisali akciju.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-delete-action .admin-form__btn').addEventListener('click', sendForm);
}


export async function setCustomDiscount(){

    const sendForm = async (e) => {
        e.preventDefault();
        let nameEntry = document.querySelector('.section-customDiscount #nameEntry').value * 1;
        let proizvodjac = document.querySelector('.section-customDiscount #proizvodjac').value.toUpperCase();
        let model = document.querySelector('.section-customDiscount #model').value.toUpperCase();
        let sezona = document.querySelector('.section-customDiscount #sezona').value.toUpperCase();
        let visina = document.querySelector('.section-customDiscount #visina').value;
        let sirina = document.querySelector('.section-customDiscount #sirina').value;
        let precnik = document.querySelector('.section-customDiscount #precnik').value.toUpperCase();
        let indeksBrzine = document.querySelector('.section-customDiscount #indeksBrzine').value.toUpperCase();
        let EAN = document.querySelector('.section-customDiscount #EAN').value;

        // Check data
        if(!nameEntry) return showAlert('error', 'Obavezno je navesti % popusta');

        const filterObj = {};
        if(nameEntry) filterObj.insertedDiscount = nameEntry;
        if(proizvodjac) filterObj.proizvodjac = proizvodjac;
        if(model) filterObj.model = model;
        if(sezona) filterObj.sezona = sezona;
        if(visina) filterObj.visina = visina;
        if(sirina) filterObj.sirina = sirina;
        if(precnik) filterObj.precnik = precnik;
        if(indeksBrzine) filterObj.indeksBrzine = indeksBrzine;
        if(EAN) filterObj.EAN = EAN;

        if(Object.keys(filterObj).length < 2) return showAlert('error', 'Unesite bar jedan od kriterijuma');

        // console.log('🏀 filterObj:', filterObj);
        // console.log('🏀 filterObj.keys:', Object.keys(filterObj));

        try {
            const res = await axios({
                method: 'post',
                url: '/admin/discount',
                data: filterObj
            });
            // console.log('🏀 res: ', res);

            if(res.status == 200){
                showAlert('success', 'Uspešno ste postavili popuste.');
                window.setTimeout(() => location.reload(), 3 * 1000);
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.'); // ⏳
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-customDiscount .admin-form__btn').addEventListener('click', sendForm);
}


export async function setPictureCustom(){

    const sendForm = async (e) => {
        e.preventDefault();

        let proizvodjac = document.querySelector('.section-pictureCustom #proizvodjac').value.toUpperCase();
        let model = document.querySelector('.section-pictureCustom #model').value.toUpperCase();
        let sezona = document.querySelector('.section-pictureCustom #sezona').value.toUpperCase();
        let rof = document.querySelector('.section-pictureCustom #rof').value.toUpperCase();
        let slug = document.querySelector('.section-pictureCustom #slug').value;

        const files = document.querySelector('.section-pictureCustom #nameEntry').files;
        // console.log('🏀 files', files);

        // Check data
        if(files.length === 0) return showAlert('error', 'Obavezno je izabrati sliku');

        const filterObj = {};
        if(proizvodjac) filterObj.proizvodjac = proizvodjac;
        if(model) filterObj.model = model;
        if(sezona) filterObj.sezona = sezona;
        if(rof) filterObj.rof = rof;
        if(slug) filterObj.slug = slug;

        if(Object.keys(filterObj).length < 1) return showAlert('error', 'Unesite bar jedan od kriterijuma');
        // console.log('🏀 filterObj:', filterObj);
        // console.log('🏀 filterObj.keys:', Object.keys(filterObj));

        // Create multipart form
        const form = new FormData();
        form.append('slika', document.querySelector('.section-pictureCustom #nameEntry').files[0]);
        form.append('proizvodjac', proizvodjac);
        form.append('model', model);
        form.append('sezona', sezona);
        form.append('rof', rof);
        form.append('slug', slug);

        // Send response
        try {
            const res = await axios({
                method: 'post',
                url: '/admin/pictureCustom',
                data: form
            });
            // console.log('🏀 res: ', res);

            if(res.status == 200){
                showAlert('success', 'Uspešno ste postavili slike.');
                window.setTimeout(() => location.reload(), 3 * 1000);
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.'); // ⏳
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-pictureCustom .admin-form__btn').addEventListener('click', sendForm);
}


export async function isTyreVisible(){

    const sendForm = async (e) => {
        e.preventDefault();
        let EAN = document.querySelector('.section-isTyreVisible #nameEntry').value;
        let vidljivProizvod = document.querySelector('.section-isTyreVisible #vidljivProizvod').value.toLowerCase();

        // Check data
        if(!EAN) return showAlert('error', 'Obavezno je EAN');
        if(!vidljivProizvod) return showAlert('error', 'Obavezno je odrediti da li je proizvod vidljiv.');

        const filterObj = {
            EAN,
            vidljivProizvod
        };

        // console.log('🏀 filterObj:', filterObj);

        try {
            const res = await axios({
                method: 'patch',
                url: '/admin/isVisible',
                data: filterObj
            });
            // console.log('🏀 res: ', res);

            if(res.status == 200){
                showAlert('success', 'Uspešno ste odredili da li je proizvod vidljiv.');
                window.setTimeout(() => location.reload(), 3 * 1000);
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.'); // ⏳
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-isTyreVisible .admin-form__btn').addEventListener('click', sendForm);
}


export async function setPricesFromCMS(){
    const sendForm = async (e) => {
        e.preventDefault();

        const section = document.querySelector('.section-prices-cms');
        console.log('🏀 section...: ', section.querySelector('#csv-file').files);

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('csv-file', section.querySelector('#csv-file').files[0]);

        // 2 - Provera unetih podataka
        if(!section.querySelector('#csv-file').value) return showAlert('error', 'Obavezno je uneti csv fajl.');
        // return console.log('🏀 set prices from csv...');

        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'patch',
                url: '/admin/csvPrices',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste postavili cene i popuste.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.section-prices-cms .admin-form__btn').addEventListener('click', sendForm);
}